import { SelectedPaymentOption } from '@wix/ambassador-bookings-gateway/types';
import {
  ChannelType,
  CreateCheckoutFromCurrentCartRequest,
  CreateCheckoutResponse,
} from '@wix/ambassador-ecom-v1-cart/types';
import {
  CreateOrderRequest,
  CreateOrderResponse,
  UpdateCheckoutRequest,
  UpdateCheckoutResponse,
} from '@wix/ambassador-checkout/types';
import { CartFlow, ReservedPaymentOptionIds } from '../../../../types/types';
import { CreateActionParams } from '../actions';

export type OnCartCTAClick = (cartFlow?: CartFlow) => Promise<void>;

export function createOnCartCTAClickAction({
  context,
  getControllerState,
}: CreateActionParams): OnCartCTAClick {
  return async (cartFlow?: CartFlow) => {
    const { wixSdkAdapter, formApi } = context;
    const [state] = getControllerState();
    const {
      paymentDetails,
      selectedPaymentOptionId,
      selectedPaymentType,
      cartModal,
      memberships,
      memberDetails,
    } = state;
    if (cartFlow === CartFlow.ADD_TO_CART) {
      await wixSdkAdapter.navigateAfterAddToCart();
    } else if (cartFlow === CartFlow.CHECKOUT) {
      const isSingleItemOnCart = cartModal?.lineItems?.length === 1;
      const isMembershipOptionSelected = memberships?.eligibleMemberships?.find(
        (membership) => selectedPaymentOptionId === membership.id,
      );
      const isCustomPrice =
        paymentDetails.priceText &&
        selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
      const isFreePrice =
        paymentDetails.isFree &&
        selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
      const isOfflinePayment =
        selectedPaymentType === SelectedPaymentOption.OFFLINE;
      const shouldGoToTYP =
        isMembershipOptionSelected ||
        isCustomPrice ||
        isFreePrice ||
        isOfflinePayment;

      if (isSingleItemOnCart) {
        const createCheckoutFromCurrentCartRequest: CreateCheckoutFromCurrentCartRequest = {
          channelType: ChannelType.WEB,
        };
        const checkoutFromCartResponse: CreateCheckoutResponse = await formApi.createCheckoutFromCurrentCart(
          createCheckoutFromCurrentCartRequest,
        );
        const updateCheckoutRequest: UpdateCheckoutRequest = {
          checkout: {
            id: checkoutFromCartResponse.checkoutId,
            billingInfo: {
              contactDetails: {
                firstName: memberDetails?.contact?.firstName || '',
                lastName: memberDetails?.contact?.lastName || '',
              },
            },
          },
        };
        const updateCheckoutResponse: UpdateCheckoutResponse = await formApi.updateCheckout(
          updateCheckoutRequest,
        );
        if (
          !checkoutFromCartResponse.checkoutId ||
          !updateCheckoutResponse ||
          !shouldGoToTYP
        ) {
          await wixSdkAdapter.navigateToEcomCart();
        }
        if (shouldGoToTYP) {
          const createOrderRequest: CreateOrderRequest = {
            id: checkoutFromCartResponse.checkoutId,
          };
          const orderFromCheckoutResponse: CreateOrderResponse = await formApi.createOrderFromCheckout(
            createOrderRequest,
          );
          if (orderFromCheckoutResponse.orderId) {
            await wixSdkAdapter.navigateToEcomThankYouPage({
              orderId: orderFromCheckoutResponse.orderId,
            });
          }
        }
      } else {
        await wixSdkAdapter.navigateToEcomCart();
      }
    } else {
      await wixSdkAdapter.navigateToServiceListWithQuery('', '');
    }
  };
}
